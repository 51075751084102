.contact__container {
    background-color: #131920;
}

.contact__text--title {
    color: white;
    text-align: center;
    font-size: 2.5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.contact__text--sub {
    color: white;
    text-align: center;
    font-size: 1.4rem;
    padding: 0 2rem;
    margin-bottom: 2rem;
}

.contact__form {
    width: 27em;
    margin: 0 auto 1.5rem auto;
    display: flex;
    flex-direction: column;
}

.contact__form--field {
    padding: 0.5rem 1rem;
    border-radius: 10px;
    border-color: transparent;
    text-align: center;
    border-width: 0;
    margin-bottom: 0.5rem;
    color: #FFFFFF;
    background-color: #20262d;
    font-size: 1.3rem;
    width: 25rem;
}

.contact__form--field::placeholder {
    color: #a1a1a1;
}

#contact__name,
#contact__email,
#contact__subject {
    font-size: 1.4rem;
}

#contact__message {
    font-family: 'Lato';
    resize: none;
}

.contact__send {
    margin: 0.5rem auto 0 auto;
    font-size: 1.8rem;
    padding: 0.75rem 3rem;
    border-radius: 20px;
    border-color: transparent;
    background-color: #223cb2;
    color: white;
    cursor: pointer;
}