/*NAV-BAR*/

nav {
    background-color: #0a1d74;
    height: 40px;
    margin-top: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
}

.nav__container {
    height: 100%;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
}

.menu--mobile {
    background-color: #172a99;
    position: fixed;
    width: 100%;
    left: 0px;
    top: 60px;
    padding: 0;
    margin: 0;
    z-index: 50;
    visibility: hidden;
}

.menu--desktop {
    display: none;
    justify-content: space-between;
    align-items: center;
    width: 55%;
    max-width: 500px;
}


.menu__item {
    color: white;
    font-size: 20px;
    cursor: pointer;
    padding: 10px 0px 10px 0px;
}

.menu__item--mobile {
    display: block;
    width: 100%;
    text-align: center;
    opacity: 0;
}

.menu__item--desktop {
    display: inline-block;
    padding: 10px;
}


.nav__logo {
    height: 45px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}


.menu__icon {
    color: white;
    width: 36px;
    height: 24px;
    position: relative;
    float: right;
    top: 50%;
    transform: translateY(-50%);
}

.menu__icon span {
    display: block;
    width: 100%;
    height: 4px;
    background: white;
    border-radius: 20px;
    position: absolute;
    transform: rotate(0deg);
    transition: transform .8s ease-in-out;
}

.menu__icon span:nth-child(1) {
    top: 0px;
}

.menu__icon span:nth-child(2) {
    top: 10px;
    transition: opacity .8s ease-in-out, transform .8s ease-in-out;
}

.menu__icon span:nth-child(3) {
    bottom: 0px;
}

.menu__icon.open span:nth-child(1) {
    transform: rotate(-135deg) translateX(-8px) translateY(-10px);
}

.menu__icon.open span:nth-child(2) {
    transform: translateX(-60%);
    opacity: 0%;
}

.menu__icon.open span:nth-child(3) {
    transform: rotate(135deg) translateX(-4px) translateY(6px);
}

@media screen and (min-width: 1024px) {
    .menu--desktop {
        display: flex;
    }

    .menu--mobile {
        display: none;
    }

    .menu__icon {
        display: none;
    }
}

@keyframes open-menu {
    0% {
        transform: translateY(-300px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes close-menu {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-300px);
    }
}

@keyframes fadein-menu {
    0% {
        opacity: 0%;
    }

    100% {
        opacity: 100%;
    }
}

@keyframes fadeout-menu {
    0% {
        opacity: 100%;
    }

    100% {
        opacity: 0%;
    }
}




.menu__animation--open {
    animation: open-menu 1s ease-out;
    visibility: visible;
    display: block;
}

.menu__animation--close {
    animation: close-menu 1s ease-in forwards;
    transition-delay: 1.2s;
}

.menu__animation--fadein {
    animation: fadein-menu .3s ease forwards;
    animation-delay: calc(750ms + var(--order) * 200ms);
}

.menu__animation--fadeout {
    animation: fadeout-menu .2s ease forwards;
}