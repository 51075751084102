* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Lato', 'Courier New', Courier;
  margin: 0;
  padding: 0;
  background-color: black;
}

.main {
  width: 100%;
}

html {
  font-size: 10px;
}

@media screen and (min-width: 640px) {
  html {
    font-size: 11px;
  }
}

@media screen and (min-width: 768px) {
  html {
    font-size: 13px;
  }
}

@media screen and (min-width: 1280px) {
  html {
    font-size: 14px;
  }
}