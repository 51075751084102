/*002855*/


.main {
    display: flex;
    flex-direction: column;
    top: 60px;
    position: absolute;
}

.hero__container {
    height: 60rem;
    position: relative;
    background: linear-gradient(to bottom right, #416ab6, #13136c);
}

.hero__center__elements {
    margin: 0;
    width: 85%;
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
}

.hero__vector {
    position: absolute;
    top: 0;
    right: 0;
    width: 20rem;
}

.hero__text--main {
    font-size: 3.8rem;
    margin-bottom: 2rem;
    color: white;
}

.hero__text--sub {
    font-size: 2rem;
    font-weight: 300;
    margin-bottom: 3rem;
    color: white;
    max-width: 50rem;
}

.hero__cta {
    width: 15rem;
    height: 4.25rem;
    border-radius: 30px;
    border-color: transparent;
    background-color: #b10f2e;
    color: #FFFFFF;
    font-family: 'Lato';
    font-size: 1.6rem;
    cursor: pointer;
}


@media (orientation: landscape) and (max-height: 480px) {
    .hero__container {
        height: 32rem;
    }

    .hero__center__elements {
        left: 7.5%;
    }

    .hero__vector {
        width: 16rem;
    }
}