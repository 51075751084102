.about__container {
    padding: 8rem 10% 5rem 10%;
    background-color: white;
}


.about--main {
    font-size: 3.5rem;
    color: #0a1d74;
    margin-bottom: 2.5rem;
    display: table;
}

.about--main::after {
    content: '';
    width: 100%;
    height: 0.4rem;
    background-color: #705283;
    display: block;
}

.about__section {
    font-family: 'Roboto Mono';
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 8rem;
    border-bottom-style: solid;
    border-width: 1px;
    border-color: #cccccc;
}


.about__overview,
.about__enhancement {
    flex-direction: column;
}

.about__overview--image,
.about__mvp--image,
.about__enhancement--image {
    width: 70%;
}

.about__overview--text,
.about__mvp--text,
.about__enhancement--text {
    margin-bottom: 4rem;
}

.about__overview--sub {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: rgb(91, 91, 91);
}

.about__paragraph {
    font-size: 1.3rem;
}


.about__mvp {
    flex-direction: column-reverse;
    margin-top: 2rem;
}


.about__mvp--sub,
.about__enhancement--sub {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: #0a1d74;
}

.cta__section {
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cta__sub {
    font-size: 3rem;
    margin-bottom: 0.5rem;
    color: #5a3870;
}

.cta__text {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
}

.cta__button {
    font-size: 1.8rem;
    padding: 1rem 1.5rem 1rem 1.5rem;
    width: 16rem;
    border-radius: 20px;
    border-color: transparent;
    background-color: #b10f2e;
    color: white;
}

@media (min-width: 720px) {

    .about__overview,
    .about__mvp,
    .about__enhancement {
        flex-direction: row;
    }

    .about__overview--image,
    .about__mvp--image,
    .about__enhancement--image {
        width: 30rem;
    }

    .about__overview--text,
    .about__enhancement--text {
        padding-right: 6rem;
    }

    .about__mvp--text {
        padding-left: 6rem;
    }
}